import { createGlobalState } from "react-hooks-global-state";

const { getGlobalState, useGlobalState, setGlobalState } = createGlobalState({
    "auction": null,
    "auctionAddresses": [],
    "auctionContract": "",
    "auctions": [],
    "bidBox": "scale-0",
    "bidders": [],
    "boxModal": "scale-0",
    "collections": [],
    "connectedAccount": null,
    "contracts": "",
    "currentBidCount": 0,
    "currentPrice": 0.0,
    "currentUser": null,
    "group": null,
    "offerModal": "scale-0",
    "priceModal": "scale-0",
    "userData": {
        "address": null,
        "bidBalance": null,
        "presaleData": {
            "balance": null,
            "burnLimit": null,
            "burnToStakeRatio": null,
            "burntAmount": null,
            "discount": null,
            "initialStakingLimit": null,
            "isActive": false,
            "maxStakingLimit": null,
            "maxTokensPerUser": null,
            "rate": null
        },
        "totalCirculatingSupply": null,
        "totalReward": null,
        "totalStaked": null,
        "totalStakedContract": null,
        "usdtBalance": null
    },
    "userReferralData": {
        "childrenRef": [],
        "claimableReward": 0,
        "parentRef": null,
        "refCode": "",
        "refCount": 0,
        "refLink": "",
        "totalCommissionEarned": 0,
        "totalReferralRewards": 0,
        "walletAddress": ""
    },
    "web3": ""
});

/**
 *
 */
const truncate = (text, startChars, endChars, maxLength) => {
    if (text.length > maxLength) {
        let start = text.substring(0, startChars);
        const end = text.substring(text.length - endChars, text.length);
        while (start.length + end.length < maxLength) {
            start = start + ".";
        }

        return start + end;
    }

    return text;
};

/**
 *
 */
const convertToSeconds = (minutes, hours, days) => {
    const seconds = minutes * 60 + hours * 3600 + days * 86400;
    const timestamp = new Date().getTime();

    return timestamp + seconds;
};

export {
    getGlobalState,
    useGlobalState,
    setGlobalState,
    truncate,
    convertToSeconds
};
