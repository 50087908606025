import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import {
    useAppKit,
    useAppKitEvents,
    useAppKitState
} from "@reown/appkit/react";
import { useAuth } from "../../Services/AuthContext";
import RegistrationForm from "../RegistrationForm";
import LoginForm from "../LoginForm";
import { useSIWEAuth } from "../../Services/useSIWEAuth";

/**
 *
 */
export const useOnboarding = () => {
    const [showOnboarding, setShowOnboarding] = useState(false);

    /**
     *
     */
    const startOnboarding = () => {
        localStorage.removeItem("onboardingComplete");
        setShowOnboarding(true);
    };

    /**
     *
     */
    const closeOnboarding = () => {
        setShowOnboarding(false);
    };

    return { closeOnboarding, showOnboarding, startOnboarding };
};

/**
 *
 */
const OnboardingFlow = ({ onClose }) => {
    const [step, setStep] = useState(1);
    const [direction, setDirection] = useState(0);
    const [hasSeenOnboarding, setHasSeenOnboarding] = useState(false);
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        const onboardingComplete = localStorage.getItem("onboardingComplete");
        if (onboardingComplete || user) {
            setHasSeenOnboarding(true);
        }
    }, [user]);

    /**
     *
     */
    const handleNext = () => {
        setDirection(1);
        setStep((prev) => prev + 1);
    };

    /**
     *
     */
    const handlePrev = () => {
        setDirection(-1);
        setStep((prev) => prev - 1);
    };

    /**
     *
     */
    const completeOnboarding = () => {
        localStorage.setItem("onboardingComplete", "true");
        setHasSeenOnboarding(true);
        navigate("/dashboard");
        onClose?.();
    };

    if (hasSeenOnboarding) {
        return null;
    }

    return (
        <motion.div
            initial={{ "opacity": 0 }}
            animate={{ "opacity": 1 }}
            exit={{ "opacity": 0 }}
            className="fixed inset-0 bg-black/90 z-20 flex items-center justify-center">
            <div className="bg-gray-800 rounded-lg p-8 max-w-2xl w-full mx-4 relative overflow-hidden">
                <div className="mb-4">
                    <div className="flex justify-between items-center mb-8">
                        <motion.h2
                            initial={{ "opacity": 0, "x": -20 }}
                            animate={{ "opacity": 1, "x": 0 }}
                            className="text-2xl font-bold text-yellow-400">
              Welcome to BidCoin
                        </motion.h2>
                        <div className="flex gap-2">
                            {[1, 2, 3, 4].map((i) => (
                                <motion.div
                                    key={i}
                                    initial={{ "scale": 0 }}
                                    animate={{ "scale": 1 }}
                                    transition={{ "delay": i * 0.1 }}
                                    className={`w-2 h-2 rounded-full ${
                    i <= step ? "bg-yellow-400" : "bg-gray-600"
                                    }`}
                                />
                            ))}
                        </div>
                    </div>

                    <AnimatePresence mode="wait" custom={direction}>
                        {step === 1 && (
                            <StepWrapper direction={direction} key="step1">
                                <WelcomeStep onNext={handleNext} />
                            </StepWrapper>
                        )}
                        {step === 2 && (
                            <StepWrapper direction={direction} key="step2">
                                <ProjectOverviewStep onNext={handleNext} onPrev={handlePrev} />
                            </StepWrapper>
                        )}
                        {step === 3 && (
                            <StepWrapper direction={direction} key="step3">
                                <RegistrationChoiceStep
                                    onNext={handleNext}
                                    onPrev={handlePrev}
                                />
                            </StepWrapper>
                        )}
                        {step === 4 && (
                            <StepWrapper direction={direction} key="step4">
                                <FinalStep
                                    onComplete={completeOnboarding}
                                    onPrev={handlePrev}
                                />
                            </StepWrapper>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </motion.div>
    );
};

// Wrapper component for step animations
/**
 *
 */
const StepWrapper = ({ children, direction }) => (
    <motion.div
        initial={{ "opacity": 0, "x": direction >= 0 ? 200 : -200 }}
        animate={{ "opacity": 1, "x": 0 }}
        exit={{ "opacity": 0, "x": direction >= 0 ? -200 : 200 }}
        transition={{
            "damping": 30,
            "stiffness": 300,
            "type": "spring"
        }}>
        {children}
    </motion.div>
);

/**
 *
 */
const WelcomeStep = ({ onNext }) => (
    <motion.div
        className="text-center"
        initial={{ "opacity": 0, "y": 20 }}
        animate={{ "opacity": 1, "y": 0 }}
        transition={{ "delay": 0.2 }}>
        <h3 className="text-xl text-white mb-4">
      Welcome to the Future of Bidding
        </h3>
        <p className="text-gray-300 mb-6">
      Let's get you started with BidCoin, the most advanced bidding platform in
      Web3.
        </p>
        <motion.button
            whileHover={{ "scale": 1.05 }}
            whileTap={{ "scale": 0.95 }}
            onClick={onNext}
            className="bg-yellow-400 text-black px-6 py-2 rounded-lg hover:bg-yellow-500">
      Get Started
        </motion.button>
    </motion.div>
);

/**
 *
 */
const ProjectOverviewStep = ({ onNext, onPrev }) => (
    <div className="space-y-4">
        <h3 className="text-xl text-white mb-4">What is BidCoin?</h3>
        <motion.div
            className="space-y-3 text-gray-300"
            initial="hidden"
            animate="visible"
            variants={{
                "hidden": { "opacity": 0 },
                "visible": {
                    "opacity": 1,
                    "transition": {
                        "staggerChildren": 0.2
                    }
                }
            }}>
            {[
                "🎯 Decentralized Penny Auction Platform",
                "💰 Bid with USDT or burn BID tokens",
                "🏆 Earn rewards through StakeBack system",
                "🔥 Benefit from BurnBack mechanics"
            ].map((text, index) => (
                <motion.p
                    key={index}
                    variants={{
                        "hidden": { "opacity": 0, "x": -20 },
                        "visible": { "opacity": 1, "x": 0 }
                    }}>
                    {text}
                </motion.p>
            ))}
        </motion.div>
        <div className="flex justify-between mt-6">
            <motion.button
                whileHover={{ "scale": 1.05 }}
                whileTap={{ "scale": 0.95 }}
                onClick={onPrev}
                className="bg-gray-600 text-white px-6 py-2 rounded-lg hover:bg-gray-700">
        Back
            </motion.button>
            <motion.button
                whileHover={{ "scale": 1.05 }}
                whileTap={{ "scale": 0.95 }}
                onClick={onNext}
                className="bg-yellow-400 text-black px-6 py-2 rounded-lg hover:bg-yellow-500">
        Continue
            </motion.button>
        </div>
    </div>
);

/**
 *
 */
const RegistrationChoiceStep = ({ onNext, onPrev }) => {
    const modal = useAppKit();
    const events = useAppKitEvents();
    useSIWEAuth(); // Add this line

    const [showWalletConnect, setShowWalletConnect] = useState(false);
    const [isLoginFormOpen, setIsLoginFormOpen] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    /**
     *
     */
    const openForm = () => setIsFormOpen(true);
    /**
     *
     */
    const closeForm = () => setIsFormOpen(false);
    /**
     *
     */
    const openLoginForm = () => setIsLoginFormOpen(true);
    /**
     *
     */
    const closeLoginForm = () => setIsLoginFormOpen(false);
    const state = useAppKitState();

    return (
        <div className="space-y-6">
            <h3 className="text-xl text-white mb-4">Choose How to Get Started</h3>
            <motion.div
                className="grid grid-cols-1 gap-4"
                variants={{
                    "hidden": { "opacity": 0 },
                    "visible": { "opacity": 1, "transition": { "staggerChildren": 0.2 } }
                }}
                initial="hidden"
                animate="visible">
                <motion.button
                    variants={{
                        "hidden": { "opacity": 0, "y": 20 },
                        "visible": { "opacity": 1, "y": 0 }
                    }}
                    whileHover={{ "scale": 1.02 }}
                    whileTap={{ "scale": 0.98 }}
                    onClick={openForm}
                    className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 flex items-center justify-center">
          Register with Email
                </motion.button>
                <motion.button
                    variants={{
                        "hidden": { "opacity": 0, "y": 20 },
                        "visible": { "opacity": 1, "y": 0 }
                    }}
                    whileHover={{ "scale": 1.02 }}
                    whileTap={{ "scale": 0.98 }}
                    onClick={() => modal.open()}
                    className="bg-purple-500 text-white px-6 py-3 rounded-lg hover:bg-purple-600 flex items-center justify-center">
          Connect Wallet (SIWE)
                </motion.button>
                {/* <ConnectButtonSIWE /> */}
                {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify(events, null, 2)}</pre> */}

                <motion.button
                    variants={{
                        "hidden": { "opacity": 0, "y": 20 },
                        "visible": { "opacity": 1, "y": 0 }
                    }}
                    whileHover={{ "scale": 1.02 }}
                    whileTap={{ "scale": 0.98 }}
                    onClick={openLoginForm}
                    className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 flex items-center justify-center">
          Login with Email
                </motion.button>
            </motion.div>

            <AnimatePresence>
                {isFormOpen && (
                    <motion.div
                        initial={{ "opacity": 0, "scale": 0.95 }}
                        animate={{ "opacity": 1, "scale": 1 }}
                        exit={{ "opacity": 0, "scale": 0.95 }}>
                        <RegistrationForm onClose={closeForm} isOpen={isFormOpen} />
                    </motion.div>
                )}
                {isLoginFormOpen && (
                    <motion.div
                        initial={{ "opacity": 0, "scale": 0.95 }}
                        animate={{ "opacity": 1, "scale": 1 }}
                        exit={{ "opacity": 0, "scale": 0.95 }}>
                        <LoginForm onClose={closeLoginForm} isOpen={isLoginFormOpen} />
                    </motion.div>
                )}

                {showWalletConnect && (
                    <motion.div
                        initial={{ "opacity": 0, "scale": 0.95 }}
                        animate={{ "opacity": 1, "scale": 1 }}
                        exit={{ "opacity": 0, "scale": 0.95 }}>
                        {/* <SIWEPopup
              onClose={() => setShowWalletConnect(false)}
              onConnect={onNext}
            /> */}
                        {/* <ConnectButtonSIWE /> */}
                    </motion.div>
                )}
            </AnimatePresence>

            <motion.button
                whileHover={{ "scale": 1.05 }}
                whileTap={{ "scale": 0.95 }}
                onClick={onPrev}
                className="bg-gray-600 text-white px-6 py-2 rounded-lg hover:bg-gray-700">
        Back
            </motion.button>
        </div>
    );
};

/**
 *
 */
const FinalStep = ({ onComplete, onPrev }) => (
    <motion.div
        className="text-center"
        initial={{ "opacity": 0, "scale": 0.9 }}
        animate={{ "opacity": 1, "scale": 1 }}
        transition={{ "damping": 30, "stiffness": 300, "type": "spring" }}>
        <motion.div
            initial={{ "opacity": 0, "y": 20 }}
            animate={{ "opacity": 1, "y": 0 }}
            transition={{ "delay": 0.2 }}>
            <h3 className="text-xl text-white mb-4">You're All Set!</h3>
            <p className="text-gray-300 mb-6">
        Welcome to BidCoin. You're ready to start bidding and earning rewards.
            </p>
        </motion.div>

        <div className="flex justify-between">
            <motion.button
                whileHover={{ "scale": 1.05 }}
                whileTap={{ "scale": 0.95 }}
                onClick={onPrev}
                className="bg-gray-600 text-white px-6 py-2 rounded-lg hover:bg-gray-700">
        Back
            </motion.button>
            <motion.button
                whileHover={{ "scale": 1.05 }}
                whileTap={{ "scale": 0.95 }}
                onClick={onComplete}
                className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600">
        Start Bidding
            </motion.button>
        </div>
    </motion.div>
);

export default OnboardingFlow;
