import React, { Suspense, useEffect, lazy, useState } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Confetti from "react-confetti";
import { initAllContracts, loadAuctions } from "../Services/BlockChainService";
import useSound from "../Services/SoundService";
import { initializeAuctionContract } from "../Services/AlchemyAuctionInteract";
import AppFooter from "./AppFooter";
import AppHeader from "./AppHeader";
import ErrorBoundary from "./ErrorBoundary";
import {  useLoading } from "./LoadingContext";
import HowItWorksScreen from "./HowItWorks";
import CookieConsentBanner from "./Cookies/CookieConsentBanner";
import { BlockchainOverlay } from "./LoadingScreen";
import AchievementListenerWrapper from "./Achievements/AchievementListenerWrapper";
// Lazy load all components
const HomePage = lazy(() => import("./HomePage"));
const AuctionsUI = lazy(() => import("./AuctionsUI"));
const AuctionDetails = lazy(() => import("./AuctionDetailsUI"));
// const CreateAuction = lazy(() => import("./CreateAuction"));
// const GovernanceVoting = lazy(() => import("./GovernanceUI"));
const UserProfileRewards = lazy(() => import("./Profile/UserProfile"));
// const NotificationCenter = lazy(() => import("./NotificationCenter"));
const StakingInterface = lazy(() => import("./StakingUI"));
// const PresaleUI = lazy(() => import("./PresaleUI"));
// const ReferralCommission = lazy(() => import("./ReferralUI"));
const TermsAndConditionsPage = lazy(() => import("./TermsAndCondtions"));
const LegalRefundPage = lazy(() => import("./LegalRefund"));
const RefundPage = lazy(() => import("./Refund"));
const AirdropSubscription = lazy(() => import("./AirDrop/AirDropUI"));
/**
 *
 */
const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        "height": window.innerHeight,
        "width": window.innerWidth
    });

    useEffect(() => {
        /**
         *
         */
        const handleResize = () => {
            setWindowSize({
                "height": window.innerHeight,
                "width": window.innerWidth
            });
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
};

/**
 *
 */
const CustomAppRouter = () => {
    const { isLoading, setIsLoading } = useLoading();
    const [consent, setConsent] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const { width, height } = useWindowSize(); // Add this hook to get window dimensions
    const playBidSound = useSound("/sounds/place_bid_sound.mp3");

    /**
     *
     */
    const handleAcceptCookies = () => {
        setConsent(true);
    };

    useEffect(() => {
        /**
         *
         */
        const initContracts = async () => {
            setIsLoading(true);
            await initAllContracts();
            subscribeToAllBidEvents();
            setIsLoading(false);
        };
        initContracts();
    }, [setIsLoading]);

    /**
     *
     */
    const subscribeToAllBidEvents = async () => {
        const auctionArray = await loadAuctions();
        if (auctionArray) {
            for (const auctionAddress of auctionArray) {
                const auctionContract = initializeAuctionContract(auctionAddress);
                auctionContract.events.BidPlaced({}, async (error, data) => {
                    if (error) {
                        setStatus("😥 " + error.message);
                    } else {
                        const res = data.returnValues;
                        // Check if the event was emitted by the userAddress
                        // console.log(
                        //   "🚀 ~ auctionContract.events.BidPlaced ~ es[0].toLowerCase():",
                        //   res[0].toLowerCase()
                        // );

                        console.log(
                            "BidPlaced Event emitted by: " +
                res[0] +
                ", currentBidPrice: " +
                res[1] +
                ", numberOfBids: " +
                res[2] +
                ", isBurned: " +
                res[3]
                        );
                        playBidSound();

                        toast.success(res[0] + " ~ placed a bid");
                        // setShowConfetti(true);
                        // setTimeout(() => {
                        //   setShowConfetti(false);
                        // }, 3000);
                    }
                });
            }
        }
    };

    // if (isLoading) {
    //   return <LoadingScreen />;
    // }
    return (
        <div className="min-h-screen bg-center subpixel-antialiased bg-gray-900 text-white mt-24">
            <AchievementListenerWrapper>
                {showConfetti && (
                    <Confetti width={width} height={height} gravity={0.618} />
                )}
                <AppHeader />
                <ErrorBoundary>
                    <Suspense fallback={<BlockchainOverlay loading={isLoading} />}>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/Airdrop" element={<AirdropSubscription />} />
                            <Route path="/HomePage" element={<HomePage />} />
                            <Route path="/Auctions" element={<AuctionsUI />} />
                            {/* <Route path="/AuctionDetails" element={<AuctionDetails />} /> */}
                            <Route
                                path="/AuctionDetails/:address"
                                element={<AuctionDetails />}
                            />
                            {/* <Route path="/CreateAuction" element={<CreateAuction />} /> */}
                            {/* <Route path="/GovernanceVoting" element={<GovernanceVoting />} /> */}
                            <Route path="/HowItWorks" element={<HowItWorksScreen />} />
                            <Route path="/profile" element={<UserProfileRewards />} />
                            {/* <Route path="/notifications" element={<NotificationCenter />} /> */}
                            <Route path="/StakingInterface" element={<StakingInterface />} />
                            <Route
                                path="/TermAndConditions"
                                element={<TermsAndConditionsPage />}
                            />
                            <Route path="/legalrefund" element={<LegalRefundPage />} />
                            <Route path="/refund" element={<RefundPage />} />
                            {/* <Route path="/Presale" element={<PresaleUI />} /> */}
                            {/* <Route path="/ReferralUI" element={<ReferralCommission />} /> */}
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                        <CookieConsentBanner onAccept={handleAcceptCookies} />
                    </Suspense>
                </ErrorBoundary>
                <AppFooter />
                <ToastContainer
                    position="top-left"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </AchievementListenerWrapper>
        </div>
    );
};
export default CustomAppRouter;
