import { createSIWEConfig, formatMessage } from "@reown/appkit-siwe";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// call the server to get a nonce
/**
 *
 */
const getNonce = async () => {
    const res = await fetch(BASE_URL + "/api/Siwe/nonce", {
        "credentials": "include",
        "method": "GET"
    });
    if (!res.ok) {
        throw new Error("Network response was not ok");
    }
    const nonce = await res.text();
    console.log("Nonce:", nonce);

    return nonce;
};

//TODO call the server to verify the message ++ TODO: add address+chainId to the body
/**
 *
 */
const verifyMessage = async ({ message, signature }) => {
    try {
        const response = await fetch(BASE_URL + "/api/Siwe/verify", {
            "body": JSON.stringify({ message, signature }),
            "credentials": "include",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            "method": "POST",
            "mode": "cors"
        });

        if (!response.ok) {
            console.error("Verify message failed:", await response.text());

            return false;
        }

        const result = await response.json();
        console.log("Verify message success:", result);

        // Store the entire result object, not just result.data
        localStorage.setItem("siwe-user", JSON.stringify(result));

        // Dispatch custom event with the full result
        window.dispatchEvent(
            new CustomEvent("SIWE_AUTH_COMPLETE", {
                "detail": result
            })
        );

        return true;
    } catch (error) {
        console.error("Verify message error:", error);

        return false;
    }
};

// call the server to get the session
/**
 *
 */
const getSession = async () => {
    const res = await fetch(BASE_URL + "/api/Siwe/session", {
        "credentials": "include",
        "headers": {
            "Content-Type": "application/json"
        },
        "method": "GET"
    });
    if (!res.ok) {
        throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data == "{}" ? null : data;
};

// call the server to sign out
/**
 *
 */
const signOut = async () => {
    const res = await fetch(BASE_URL + "/api/Siwe/signout", {
        "credentials": "include",
        "method": "POST"
    });
    if (!res.ok) {
        throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data == "{}";
};

/**
 *
 */
export const createSIWE = (chains) => {
    return createSIWEConfig({
        
        /**
         *
         */
        "createMessage": ({ address, ...args }) => {
            return formatMessage(args, address);
        },
        
        
        /**
         *
         */
        "getMessageParams": async () => ({
            "chains": chains.map((chain) => parseInt(chain.id.toString())),
            "domain": window.location.host,
            "expiry": 3600,
            "statement": `I accept the MetaMask Terms of Service: https://community.metamask.io/tos\n\nURI: https://${
                window.location.host
            }\nVersion: 1\nChain ID: 42161\nNonce: 32891757\nIssued At: ${new Date().toISOString()}`,
            "uri": window.location.origin
        }),
        
        
        getNonce,
        
        getSession,
        signOut,
        "signOutOnAccountChange": true,
        "signOutOnNetworkChange": true,
        verifyMessage
    });
};
