import { useRef, useCallback } from "react";

/**
 *
 */
const useSound = (soundPath, volume = 1) => {
    const audioRef = useRef(null);

    const play = useCallback(() => {
        if (!audioRef.current) {
            audioRef.current = new Audio(soundPath);
            audioRef.current.volume = Math.max(0, Math.min(1, volume));
        }

        const playPromise = audioRef.current.play();

        if (playPromise !== undefined) {
            playPromise
                .then((_) => {
                    // Automatic playback started!
                })
                .catch((error) => {
                    // Auto-play was prevented
                    console.log(
                        "Playback prevented. This is normal on mobile devices.",
                        error
                    );
                });
        }
    }, [soundPath, volume]);

    return play;
};

export default useSound;
