import React, { useEffect } from "react";
import {
    initAchievementListener,
    stopAchievementListener
} from "../../Services/AchievementListener";
import "react-toastify/dist/ReactToastify.css";

/**
 *
 */
const AchievementListenerWrapper = ({ children }) => {
    useEffect(() => {
        initAchievementListener();

        return () => stopAchievementListener();
    }, []);

    return <>{children}</>;
};

export default AchievementListenerWrapper;
