import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback
} from "react";
import { ethers, formatUnits, parseUnits } from "ethers";
import { toast } from "react-toastify";
import { getGlobalState } from "../store";
import { fetchUserData, isWalletConnected } from "./BlockChainService";

const StakingContext = createContext();

/**
 *
 */
export const StakingProvider = ({ children }) => {
    const [stakingData, setStakingData] = useState({
        "availableBalance": 0,
        "rewards": 0,
        "stakedAmount": 0,
        "totalStaked": 0
    });
    const [stakeInput, setStakeInput] = useState("");
    const [unstakeInput, setUnstakeInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const contracts = getGlobalState("contracts");
    const userData = getGlobalState("userData");
    const connectedAccount = getGlobalState("connectedAccount");
    const updateStakingData = useCallback(async () => {
        if (isLoading) {
            return;
        }

        try {
            setIsLoading(true);

            const userData = getGlobalState("userData");
            const contracts = getGlobalState("contracts");

            if (
                !userData ||
        !contracts ||
        !contracts.stakingContract ||
        !contracts.stakingContract.methods
            ) {
                console.error("Contracts or user data not properly initialized");

                return;
            }

            const stakerInfo = await contracts.stakingContract.methods
                .stakers(userData.address)
                .call();
            // console.log("🚀 ~ updateStakingData ~ stakerInfo:", stakerInfo);

            const totalStaked = await contracts.stakingContract.methods
                .totalStaked()
                .call();
            // console.log("🚀 ~ updateStakingData ~ totalStaked:", totalStaked);

            setStakingData({
                "availableBalance": userData.bidBalance || "0",
                "rewards": Number(formatUnits(stakerInfo.rewardAmount, 18)).toFixed(2),
                "stakedAmount": Number(formatUnits(stakerInfo.stakedAmount, 18)).toFixed(
                    2
                ),
                "totalStaked": Number(formatUnits(totalStaked, 18)).toFixed(2)
            });

            setError(null);
        } catch (error) {
            console.error("Error updating staking data:", error);
            setError("Failed to update staking data. Please try again.");
        } finally {
            setIsLoading(false);
        }
    }, [isLoading]);

    /**
     *
     */
    const handleStake = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const stakeAmount = parseUnits(stakeInput, 18);

            // Approve spending
            const approveTx = await contracts.bidCoin.methods
                .approve(contracts.stakingContract._address, stakeAmount.toString())
                .send({ "from": userData.address });

            console.log("Approval transaction hash:", approveTx.transactionHash);

            // Stake tokens
            const stakeTx = await contracts.stakingContract.methods
                .stake(userData.address, stakeAmount.toString())
                .send({ "from": userData.address });

            console.log("Stake transaction hash:", stakeTx.transactionHash);
            toast.success("Tokens staked successfully!");

            setStakeInput("");
            await refreshStakingData();
            setError(null);
        } catch (error) {
            console.error("Error staking tokens:", error);
            setError("Failed to stake tokens. Please try again.");
            // toast.error("Error staking tokens. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    /**
     *
     */
    const handleUnstake = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // const unstakeAmount = parseUnits(unstakeInput, 18);

            const unstakeTx = await contracts.stakingContract.methods
                .unStake() // TODO unstakeAmount
                .send({ "from": userData.address });

            console.log("Unstake transaction hash:", unstakeTx.transactionHash);
            toast.success("Tokens unstaked successfully!");

            setUnstakeInput("");
            await refreshStakingData();
            setError(null);
        } catch (error) {
            console.error("Error unstaking tokens:", error);
            setError("Failed to unstake tokens. Please try again.");
            // toast.error("Error unstaking tokens. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    /**
     *
     */
    const claimRewards = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const claimTx = await contracts.stakingContract.methods
                .claimRewards()
                .send({ "from": userData.address });

            console.log("Claim rewards transaction hash:", claimTx.transactionHash);
            toast.success("Rewards claimed successfully!");

            await refreshStakingData();
        } catch (error) {
            console.error("Error claiming rewards:", error);
            setError("Failed to claim rewards. Please try again.");
            toast.error("Error claiming rewards. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };
    const refreshStakingData = useCallback(async () => {
        setIsLoading(true);
        try {
            const account = await isWalletConnected();
            const data = await fetchUserData();
            // if (account && data) {
            // console.log("updating staking data.....");
            await updateStakingData();
            // }
        } catch (error) {
            console.error("Error refreshing staking data:", error);
            setError("Failed to refresh staking data. Please try again.");
        } finally {
            setIsLoading(false);
        }
    }, [isWalletConnected, fetchUserData, updateStakingData]);

    return (
        <StakingContext.Provider
            value={{
                claimRewards,
                error,
                handleStake,
                handleUnstake,
                isLoading,
                refreshStakingData,
                setStakeInput,
                setUnstakeInput,
                stakeInput,
                stakingData,
                unstakeInput
            }}>
            {children}
        </StakingContext.Provider>
    );
};

/**
 *
 */
export const useStaking = () => useContext(StakingContext);
