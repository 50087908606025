import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle, keyframes } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

  body, html {
    height: 100%;
    margin: 0;
    font-family: "Poppins", sans-serif;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    color: #ffffff;
    overflow: hidden;
  }
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const pulse = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 0 20px;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  animation: ${fadeInUp} 1s ease-out;
`;

const Message = styled.p`
  font-size: 1.2rem;
  max-width: 600px;
  margin-bottom: 2rem;
  animation: ${fadeInUp} 1s ease-out 0.5s both;
`;

const Loader = styled.div`
  width: 150px;
  height: 150px;
  border: 5px solid #ffffff;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite, ${fadeIn} 1s ease-out 1s both;
`;

const Glow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  opacity: 0;
  animation: ${pulse} 4s infinite;
`;

/**
 *
 */
const MigrationPage = () => {
    const [message, setMessage] = useState(
        "Our team is working hard to bring you an amazing new experience. We'll be back soon with something extraordinary!"
    );

    useEffect(() => {
        const phrases = [
            "Polishing pixels...",
            "Brewing innovation...",
            "Crafting excellence...",
            "Unleashing awesomeness...",
            "Igniting creativity..."
        ];
        let index = 0;
        const interval = setInterval(() => {
            setMessage(phrases[index]);
            index = (index + 1) % phrases.length;
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <GlobalStyle />
            <Glow />
            <Container>
                <Title>We're Evolving</Title>
                <Message>{message}</Message>
                <Loader />
            </Container>
        </>
    );
};

export default MigrationPage;
