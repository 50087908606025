import React from "react";
import { Loader2 } from "lucide-react";
import { useLocation } from "react-router-dom";
import BidCoinLogo from "../assets/currentLogo.png";
/**
 *
 */
export const LoadingScreen = () => {
    return (
        <div className="fixed inset-0 bg-gray-900 flex flex-col items-center justify-center z-50">
            <div className="text-blue-500 animate-spin mb-4">
                <Loader2 size={48} />
            </div>
            <h2 className="text-2xl font-bold text-white mb-2">Loading BidCoin</h2>
            <p className="text-gray-400 text-center max-w-md px-4">
        Preparing your decentralized auction experience...
            </p>
            <div className="mt-8 w-64 bg-gray-700 rounded-full h-2">
                <div
                    className="bg-blue-500 h-2 rounded-full animate-pulse"
                    style={{ "width": "75%" }}></div>
            </div>
            <div className="mt-4 flex space-x-2">
                {[1, 2, 3].map((i) => (
                    <div
                        key={i}
                        className="w-3 h-3 bg-blue-500 rounded-full animate-bounce"
                        style={{ "animationDelay": `${i * 0.2}s` }}></div>
                ))}
            </div>
        </div>
    );
};

const loadingPhrases = {
    //! Set phrases for each route in backend, and load once on app init func with contracts, save to localStorage to use and make null_SAFE incase localStorage is cleared
    "/": [
        "Welcome to BidCoin! Preparing your crypto journey...",
        "Loading the gateway to your blockchain adventures..."
    ],
    "/AuctionDetails/*": [
        "Decrypting the details of this blockchain treasure...",
        "Loading the specifics of this digital goldmine..."
    ],
    "/Auctions": [
        "Unveiling the hottest auctions in the cryptosphere...",
        "Polishing the digital gavel for epic bidding wars..."
    ],
    "/ReferralUI": [
        "Calculating your referral rewards...",
        "Preparing to show your network influence..."
    ],
    "/StakingInterface": [
        "Preparing your staking dashboard...",
        "Calculating potential rewards for your crypto commitment..."
    ],
    "/TermAndConditions": [
        "Loading the fine print of the crypto world...",
        "Decrypting the rules of engagement..."
    ],
    "/airdrop": [
        "Scanning the skies for your crypto rewards...",
        "Preparing to drop some blockchain goodness..."
    ],
    "/legalrefund": [
        "Preparing the details of our refund policy...",
        "Loading the fine print on getting your crypto back..."
    ],
    "/profile": [
        "Calculating your crypto achievements...",
        "Tallying up your blockchain triumphs..."
    ]
    // ... add the rest of the phrases here
};

/**
 *
 */
export const BlockchainOverlay = ({ loading }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    const phrases = loadingPhrases[currentPath] || loadingPhrases["/"];
    const randomPhrase = phrases[Math.floor(Math.random() * phrases.length)];

    if (!loading) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="text-center text-white p-4">
                <img
                    src={BidCoinLogo}
                    alt="BidCoin Logo"
                    className="w-24 h-24 mx-auto mb-4 animate-spin"
                />
                <p className="text-2xl font-bold mb-2">{randomPhrase}</p>
                <p className="text-sm">Hold tight, future crypto-champion!</p>
            </div>
        </div>
    );
};
