import { useEffect } from "react";
import { useAppKitEvents } from "@reown/appkit/react";
import { useAuth } from "./AuthContext";

/**
 *
 */
export const useSIWEAuth = () => {
    const events = useAppKitEvents();
    const { setUser } = useAuth();

    // Listen for our custom SIWE event
    useEffect(() => {
        /**
         *
         */
        const handleSIWEAuth = (event) => {
            // console.log("SIWE_AUTH_COMPLETE event received:", event.detail);
            if (event.detail) {
                setUser(event.detail);
            }
        };

        window.addEventListener("SIWE_AUTH_COMPLETE", handleSIWEAuth);

        return () => window.removeEventListener("SIWE_AUTH_COMPLETE", handleSIWEAuth);
    }, [setUser]);

    // Listen for AppKit events and handle wallet connection
    useEffect(() => {
        const handleWalletCheck = async () => {
            // Check if wallet is connected
            const provider = window.ethereum;
            if (provider) {
                try {
                    const accounts = await provider.request({ method: "eth_accounts" });
                    if (accounts && accounts.length > 0) {
                        // Wallet is connected, trigger SIWE flow programmatically
                        const siweUser = localStorage.getItem("siwe-user");
                        if (!siweUser) {
                            // Trigger your SIWE authentication flow here
                            // This depends on your specific implementation
                            window.dispatchEvent(
                                new CustomEvent("TRIGGER_SIWE_AUTH", {
                                    detail: { address: accounts[0] },
                                }),
                            );
                        }
                    }
                } catch (error) {
                    console.error("Error checking wallet connection:", error);
                }
            }
        };

        if (events?.data?.type === "track") {
            console.log("Track event detected:", events.data.event);

            if (events.data.event === "MODAL_CLOSE") {
                const siweUser = localStorage.getItem("siwe-user");
                console.log("Modal closed, checking siweUser:", siweUser);

                if (siweUser) {
                    try {
                        const userData = JSON.parse(siweUser);
                        console.log("Found stored user data:", userData);
                        setUser(userData);
                    } catch (error) {
                        console.error("Error parsing siweUser:", error);
                    }
                } else {
                    // If no siweUser, check if wallet is connected
                    handleWalletCheck();
                }
            }
        }
    }, [events, setUser]);
};
