import React, { useEffect } from "react";
import { onCLS, onFID, onLCP } from "web-vitals";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";

// import { Integrations } from "@sentry/tracing";
// import { SIWEPopup } from "../Components/Common/One-Click-Auth/UpdatedLogin";
import { createAppKit } from "@reown/appkit/react";
import { arbitrum, mainnet, AppKitNetwork } from "@reown/appkit/networks";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import { WagmiProvider } from "wagmi";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "../Services/AuthContext";
import { StakingProvider } from "../Services/StakingContext";
import { LoadingProvider } from "./LoadingContext";
import CustomAppRouter from "./CustomAppRouter";
import { createSIWE } from "./Common/One-Click-Auth/siweConfig";
import OnboardingFlow from "./Onboarding/OnboardingFlow";
Sentry.init({
    
    "dsn": "https://3d94b76063ff4e2a1a0e0c713639bb7e@o4505849017270272.ingest.us.sentry.io/4508080612048896",
    // enabled in production only
    "enabled": process.env.NODE_ENV === "production",
    "integrations": [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
    ],
    
    
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    "replaysOnErrorSampleRate": 1.0, 
  
  
    
    
    // Session Replay
    "replaysSessionSampleRate": 0.1,
  
    
    
    
    
    //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    "tracePropagationTargets": ["localhost", "bidcoin.live", "api.bidcoin.live"], 
    
    
    // Tracing
    "tracesSampleRate": 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
LogRocket.init("eubsbl/bidcoin-beta");
onCLS(console.log);
onFID(console.log);
onLCP(console.log);

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.reown.com
const projectId = process.env.REACT_APP_REOWN_PROJECT_ID;
if (!projectId) {
    throw new Error("Project ID is undefined");
}

// 2. Create metadata
const metadata = {
    "description":
    "Decentralized Penny Auction, the most advanced bidding platform of Web3.",
    "icons": ["https://bidcoin.live/favicon.ico"],
    "name": "Bidcoin",
    "url": "https://bidcoin.live/"
};

// 3. Set the networks
export const chains = [arbitrum, mainnet];

// 4. Create Wagmi Adapter
const wagmiAdapter = new WagmiAdapter({
    "networks": chains,
    "networks": chains,
    projectId,
    "ssr": true
});

// 5. Create a SIWE configuration object
export const siweConfig = createSIWE(chains);

// 6. Create modal
export const modal = createAppKit({
    "adapters": [wagmiAdapter],
    "features": {
        "email": true, 
        "emailShowWallets": true,
        
        // default to true
        "legalCheckbox": true, 
        // default to true
        "socials": ["google", "x", "github", "discord", "apple", "facebook"]
    },
    metadata,
    "networks": chains,
    projectId,
    "siweConfig": siweConfig,
    "themeMode": "dark",
    "themeVariables": {
        "--w3m-color-mix": "#00DCFF",
        "--w3m-color-mix-strength": 20
    }
});
/**
 *
 */
const AppRouter = () => {
    useEffect(() => {
        localStorage.setItem("welcomePopupShown", "false");
    }, []);
    /**
     *
     */
    const handleConnect = () => {
    // Implement your wallet connection logic here
        console.log("Connecting wallet...");
    };

    return (
        <div className="App">
            <WagmiProvider config={wagmiAdapter.wagmiConfig}>
                <QueryClientProvider client={queryClient}>
                    <LoadingProvider>
                        <AuthProvider>
                            {/* <UserDataProvider> */}
                            <StakingProvider>
                                {/* <SIWEPopup
              onConnect={handleConnect}
              onClose={() => console.log("Popup closed")}
            /> */}
                                <OnboardingFlow />
                                <CustomAppRouter />
                            </StakingProvider>
                            {/* </UserDataProvider> */}
                        </AuthProvider>
                    </LoadingProvider>
                </QueryClientProvider>
            </WagmiProvider>
      //{" "}
        </div>
    );
};

export default AppRouter;
