import React, { useEffect, useState } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import "./CookieConsentBanner.css";
/**
 *
 */
const CookieConsentBanner = ({ onAccept }) => {
    useEffect(() => {
        const consent = getCookieConsentValue("yourCookieConsent");
        if (consent) {
            onAccept();
        }
    }, [onAccept]);
    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept All"
            declineButtonText="Decline"
            cookieName="yourCookieConsent"
            onAccept={onAccept}
            enableDeclineButton
            style={{
                background: "rgba(43, 55, 59, 0.95)",
                backdropFilter: "blur(8px)",
                boxShadow: "0 -4px 20px rgba(0, 0, 0, 0.15)",
                padding: "12px 20px",
                maxWidth: "600px",
                margin: "0 auto 20px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "15px",
                position: "fixed",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: "0",
            }}
            buttonStyle={{
                background: "#4CAF50",
                color: "white",
                fontSize: "13px",
                padding: "8px 16px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
                minWidth: "100px",
                whiteSpace: "nowrap",
            }}
            declineButtonStyle={{
                background: "transparent",
                color: "#ffffff",
                fontSize: "13px",
                padding: "8px 16px",
                borderRadius: "4px",
                border: "1px solid #ffffff",
                cursor: "pointer",
                minWidth: "100px",
                whiteSpace: "nowrap",
            }}
            buttonWrapperClasses="cookie-buttons-wrapper"
        >
            <div style={{ flex: 1 }}>
                <p
                    style={{
                        color: "white",
                        fontSize: "16px",
                        lineHeight: "1.4",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0",
                    }}
                >
                    🍪 We use cookies to enhance your browsing experience. <br />
                    By clicking "Accept All", you consent to our use of cookies.
                </p>
            </div>
        </CookieConsent>
    );
};
export default CookieConsentBanner;
